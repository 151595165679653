import Q from "q";
import moment from "moment";

// 获取定位城市
import get_local_city from "@/lib/common-service/get_local_city/index.js";
// 团期
import web_common_userBuyer_GroupStage from "@/lib/data-service/default/web_common_userBuyer_GroupStage";
// 城市组件
import TravelCitySelector from "yinzhilv-js-sdk/frontend/common-project/component/yinzhilv/travel-city-selector/1.1.1/index.vue";
//首推产品搜索列表
import web_common_TourismMall_recommendProductList from "@/lib/data-service/default/web_common_TourismMall_recommendProductList";
import to_boolean from "nllib/src/convert/to_boolean";

export default {
    components: {
        // 城市组件
        TravelCitySelector,
    },
    data() {
        return {
            // 是否带注册地城市搜索
            is_auto_add_city: true,
            // 注册地城市名和id
            cityId: "",
            cityName: "",
            // 选中出发城市列表
            fromCityArr: [],
            // 选中到达城市列表
            toCityArr: [],
            loading: false,
            hotList: [], // 热门推荐
            hotListTotal: "",
            specialList: [], // 特价单品
            specialListTotal: "",
            highList: [], // 高利润
            highListTotal: "",
            form: {
                managementType: "",
                hotPageNo: 1, // 热门推荐当前页
                hotPageSize: 10, // 热门推荐显示数量
                spePageNo: 1, // 特价产品当前页
                spePageSize: 10, // 特价产品显示数量
                proPageNo: 1, // 高利润当前页
                proPageSize: 10, // 高利润显示数量
                fromCityId: "", // 出发城市id
                fromCityName: "", // 出发城市名
                toCityId: "", // 目的城市id
                toCityName: "", // 目的城市名
            },
            isRefresh: true
        }
    },
    methods: {
        init() {
            const __this = this;
            const form = __this.form;
            const localCity = get_local_city();
            __this.cityId = localCity.cityId;
            __this.cityName = localCity.cityName;
            if (localCity.cityId) {
                // 延迟用来解决城市组件还没加载完
                __this.$nextTick(() => {
                    form.fromCityName = __this.cityName;
                    form.fromCityId = __this.cityId;//当带城市
                    if (form.fromCityId) {
                        __this.fromCityArr = [
                            ...localCity.cityId
                                .toString()
                                .split(",")
                                .map((item) => (item = Number(item))),
                        ];
                    }
                });
            }
        },

        toHome() {
            const __this = this;
            __this.$router.push({
                name: "distributor-tour-front-home",
            });
            document.documentElement.scrollTop = 0;
        },
        set_scrollTop() {
            const __this = this;
            let top = 0;
            if (__this.isRefresh) {
                __this.$nextTick(() => {
                    switch (Number(__this.$route.query.classifyType)) {
                        case 1:
                            top = 0;
                            break;
                        case 2:
                            top = __this.$refs.proList2.getBoundingClientRect().top - 200;
                            break;
                        case 3:
                            top = __this.$refs.proList3.getBoundingClientRect().top - 200;
                            break;
                        default:
                            top = 0;
                    }
                    window.scroll(0, top)
                });
                __this.isRefresh = false
            }
        },
        get_pro_list() {
            const __this = this;
            __this.loading = true;
            web_common_TourismMall_recommendProductList(__this.form).then(res => {
                const hotList = res.recommendProductResults[0].solrProducts;
                hotList.forEach((item) => {
                    item.isMore = false;
                });
                __this.hotListTotal = parseInt(res.recommendProductResults[0].total);
                __this.hotList = hotList;

                const specialList = res.recommendProductResults[1].solrProducts;
                specialList.forEach((item) => {
                    item.isMore = false;
                });
                __this.specialListTotal = parseInt(res.recommendProductResults[1].total);
                __this.specialList = specialList;

                const highList = res.recommendProductResults[2].solrProducts;
                highList.forEach((item) => {
                    item.isMore = false;
                });
                __this.highListTotal = parseInt(res.recommendProductResults[2].total);
                __this.highList = highList;
                __this.set_scrollTop();
            }).finally(() => {
                __this.loading = false;
            });
        },
        hot_load_more() { // 热门点击加载更多
            const __this = this;
            __this.form.hotPageSize = __this.form.hotPageSize + 10;
            __this.get_pro_list();
        },
        special_load_more() { // 热门点击加载更多
            const __this = this;
            __this.form.spePageSize = __this.form.spePageSize + 10;
            __this.get_pro_list();
        },
        high_load_more() { // 高利润点击加载更多
            const __this = this;
            __this.form.proPageSize = __this.form.proPageSize + 10;
            __this.get_pro_list();
        },
        req_team_data(params) {
            return Q.when().then(() => {
                return web_common_userBuyer_GroupStage(params);
            });
        },
        to_more(productListData, index, params) {
            const __this = this;
            Q.when().then(() => {
                return __this.req_team_data(params);
            }).then(function(res) {
                productListData[index].teamData = res.pageResult.pageData;
                productListData[index].hasNext = res.pageResult.hasNext;
                productListData[index].isMore = true;
            });
        },
        get_more_team(productItem, productNo) {
            const __this = this;
            Q.when().then(function() {
                return __this.req_team_data({
                    productNo,
                    currentPage: productItem.currentPage,
                });
            }).then(function(res) {
                productItem.hasNext = res.pageResult.hasNext;
                productItem.teamData.push(...res.pageResult.pageData);
            });
        },

        /* 热门推荐 更多 */
        hot_to_more(index, productNo) {
            const __this = this;
            const productListData = __this.hotList;
            productListData[index].currentPage = 1;
            const params = {currentPage: 1, productNo };
            __this.to_more(productListData, index, params);
        },
        hot_get_more_team(index, productNo) { // 显示更多
            const __this = this;
            if (!__this.hotList[index].hasNext) return;
            const productItem = __this.hotList[index];
            productItem.currentPage += 1;
            __this.get_more_team(productItem, productNo);
        },
        hot_fold_more(index, node) { // 收起更多
            const __this = this;
            __this.hotList[index].isMore = false;
        },
        /* 热门推荐 更多 end */

        /* 特价单品更多 */
        special_to_more(index, productNo) {
            const __this = this;
            const productListData = __this.specialList;
            productListData[index].currentPage = 1;
            const params = {currentPage: 1, productNo };
            __this.to_more(productListData, index, params);
        },
        special_get_more_team(index, productNo) { // 显示更多
            const __this = this;
            if (!__this.specialList[index].hasNext) return;
            const productItem = __this.specialList[index];
            productItem.currentPage += 1;
            __this.get_more_team(productItem, productNo);
        },
        special_fold_more(index, node) { // 收起更多
            const __this = this;
            __this.specialList[index].isMore = false;
        },
        /* 特价单品更多end */

        /* 高利润更多 */
        high_to_more(index, productNo) {
            const __this = this;
            const productListData = __this.highList;
            productListData[index].currentPage = 1;
            const params = {currentPage: 1, productNo };
            __this.to_more(productListData, index, params);
        },
        high_get_more_team(index, productNo) { // 显示更多
            const __this = this;
            if (!__this.highList[index].hasNext) return;
            const productItem = __this.highList[index];
            productItem.currentPage += 1;
            __this.get_more_team(productItem, productNo);
        },
        high_fold_more(index, node) { // 收起更多
            const __this = this;
            __this.highList[index].isMore = false;
        },
        /* 高利润更多end */



        to_details(query) { // 立即预订
            PubSub.publish("travelKeywords");
            const { href } = this.$router.resolve({
                name: "distributor-tour-front-details",
                query,
            });
            window.open(href, "_blank");
        },
        to_reserve_team(productNo, startTime, tourTeamId) { // 预订
            const __this = this;
            startTime = moment(startTime).format("YYYY-MM-DD");
            const params = { productNo, startTime, tourTeamId };
            __this.to_reserve(params);
        },
        to_reserve(query) {
            const { href } = this.$router.resolve({
                name: "distributor-tour-front-reserve",
                query,
            });
            window.open(href, "_blank");
        },
    },
    created() {
        const __this = this;
        __this.init();
        __this.get_pro_list();
    },
    mounted() {
    },
    watch: {
        fromCityArr: {
            handler(val) {
                const __this = this;
                const form = __this.form;
                if (val.length > 0) {
                    const list = __this.$refs.fromCity.get_selected_entity_list({
                        latest_entity_id_list: val,
                    });
                    const arr = list.map((item) => item.areaName);
                    form.fromCityId = val.join();
                    form.fromCityName = arr.join() ? arr.join() : __this.cityName;
                } else {
                    form.fromCityId = '';
                    form.fromCityName = '';
                }
                __this.form.hotPageSize = 10;
                __this.form.spePageSize = 10;
                __this.form.proPageSize = 10;
                __this.get_pro_list();
            },
            deep: true,
        },
        toCityArr: {
            handler(val) {
                const __this = this;
                const form = __this.form;
                if (val.length > 0) {
                    const list = __this.$refs.toCity.get_selected_entity_list({
                        latest_entity_id_list: val,
                    });
                    const arr = list.map((item) => item.areaName);
                    form.toCityId = val.join();
                    form.toCityName = arr.join();
                } else {
                    form.toCityId = '';
                    form.toCityName = '';
                }
                __this.form.hotPageSize = 10;
                __this.form.spePageSize = 10;
                __this.form.proPageSize = 10;
                __this.get_pro_list();
            },
            deep: true,
        },
    },
    filters: {
        format_date(val) {
            return val === '' ? '' : moment(val).format('YYYY-MM-DD')
        },
        filterStr(val) {
            if (val) return val.replace(/\$\$/g, ",");
        },
        filterManagementType(val) {
            switch (Number(val)) {
                case 1:
                    return '跟团游';
                    break;
                case 2:
                    return '自由行';
                    break;
                case 3:
                    return '当地游';
                    break;
                case 4:
                    return '半自助';
                    break;
            }
        },
        format_areaType(val) {
            switch (Number(val)) {
                case 1:
                    return '国内';
                    break;
                case 2:
                    return '出境';
                    break;
            }
        },

        format_productLevel (val) {
            switch (Number(val)) {
                case 1:
                    return '经典';
                    break;
                case 2:
                    return '经济';
                    break;
                case 3:
                    return '促销';
                    break;
                case 4:
                    return '尊享';
                    break;
                // case 5:
                //   return '转谈';
                //   break;
                case 6:
                    return '臻品';
                    break;
            }
        },

        format_trafficMode(trafficMode) {
            switch (trafficMode) {
                case "1":
                    return "航空";
                    break;
                case "2":
                    return "高铁";
                    break;
                case "3":
                    return "动车";
                    break;
                case "4":
                    return "火车";
                    break;
                case "5":
                    return "旅游大巴";
                    break;
                case "6":
                    return "其他";
                    break;
            }
        },
        format_collectGuestType(collectGuestType) {
            switch (collectGuestType) {
                case "1":
                    return "散拼团";
                    break;
                case "2":
                    return "小包团";
                    break;
            }
        },
        format_haveSelfItem(haveSelfItem) {
            switch (haveSelfItem) {
                case "1":
                    return "有自费";
                    break;
                case "2":
                    return "无自费";
                    break;
            }
        },
        format_haveShopping(haveShopping) {
            switch (haveShopping) {
                case "1":
                    return "有购物";
                    break;
                case "2":
                    return "无购物";
                    break;
            }
        },
        format_hotelLevel(hotelLevel) {
            switch (hotelLevel) {
                case "1":
                    return "民宿";
                    break;
                case "2":
                    return "二星级及以下";
                    break;
                case "3":
                    return "准三星";
                    break;
                case "4":
                    return "三星级";
                    break;
                case "5":
                    return "准四星";
                    break;
                case "6":
                    return "四星级";
                    break;
                case "7":
                    return "准五星";
                    break;
                case "8":
                    return "五星";
                    break;
                default:
                    return "暂无星级";
                    break;
            }
        },
    }
};
